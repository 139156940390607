
table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #ec1c24;
}
.table-img {
  display: block;
  margin: 20px auto; /* Center the image */
  max-width: 100%; /* Ensure image doesn't overflow */
}
