.sec-4{
    display: flex;
    margin-bottom: 3rem;
}
.sec-4-2{
    margin-top: 10%;
    width: 38%;
}
.sec-4-2-1{
    color: #1D1D1D;
    font-family: Nunito;
    font-size: 3rem;
    font-weight: 800;
    line-height: 110%;
    margin-bottom: 2.0rem;   
}
.sec-4-2-2{
    color: #475569;
    font-family: Poppins;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 160%;
    margin-bottom: 2.0rem;
}
.sec-4-2-3{
    display: flex;
}
.sec-4-2-3 p{
    color: #EC1C24;
    font-family: Nunito;
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.5px;
}
@media only screen and (min-width:650px) and (max-width:766px) {
    .sec-4-2{
        /* display: block; */
        position: absolute;
    }
    .sec-4-1{
        margin-top: 50%;
    }
    .sec-4-2-1{
        width: 200%;
        margin-left: 5.5rem;
    }
    .sec-4-2-2{
        margin-left: 2.8rem;
        width: 225%;
    }
    .sec-4-2-3 {
        align-items: center;
        margin-left: 5.9rem;
    }
    .sec-4-2-3 p{
        width: 100%;
    }
}
@media only screen and (min-width:768px) and (max-width:1023px) {
    /* .sec-4-2{
        position: absolute;
    } */
    .sec-4-1{
        width: 50%;
    }
    .sec-4-2-1{
        width: 200%;
        font-size: 2rem;
        margin-left: 1rem;
    }
    .sec-4-2-2{
        margin-left: 1.2rem;
        width: 110%;
    }
    .sec-4-2-3 {
        margin-left: 1.5rem;
    }
    .sec-4-2-3 p{
        width: 42%;
    }
}
@media only screen and (min-width:1024px) and (max-width:1440px) {
    .sec-4-1{
        width: 55%;
    }
    .sec-4-2-1{
        width: 200%;
        font-size: 2rem;
        margin-left: 1rem;
    }
    .sec-4-2-2{
        margin-left: 1.2rem;
        width: 110%;
    }
    .sec-4-2-3 {
        margin-left: 1.5rem;
    }
    .sec-4-2-3 p{
        width: 32%;
    }
}