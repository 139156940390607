@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

team2_bg {
  background: #e8eaef;
  min-height: 100vh;
}

.about_info {
  display: hidden;
}



a {
  text-decoration: none;
}

/* img {
  display: block;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: top;
} */

section {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
  padding-top: 3%;
  text-align: center;
  width: calc(min(90rem, 90%));
  margin-inline: auto;
}

section p,
section h2,
section h3 {
  letter-spacing: 0.035rem;
}
section p {
  line-height: 1.7;
  color: #454545;
  max-width: 70rem;
  margin: 0 auto;
  font-size: clamp(0.9rem, 0.825rem + 0.3vw, 1.2rem);
}
section h2 {
  font-size: clamp(3rem, 2.4286rem + 2.2857vw, 5rem);
  color: #111;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 1rem;
}

section .cards {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(16rem, 100%), 1fr));
  gap: 2rem;
}
@media screen and (min-width: 51rem) {
  section .cards {
    gap: 3.5rem;
  }
}
section .cards .card {
  position: relative;
  text-align: left;
  /*hover states*/
}
section .cards .card .social-icon {
  background: #111;
  padding: 1rem 1.75rem;
  display: inline-block;
  position: absolute;
  left: 0;
}
section .cards .card .social-icon i {
  color: #fff;
  font-size: 1.4rem;
}
section .cards .card .social-icon:hover i {
  color: #003ef5;
}
section .cards .card img {
  filter: grayscale(100%);
  transition: 0.5s ease;
}
section .cards .card img:hover {
  filter: grayscale(0%);
}
section .cards .card .card-content-wrapper {
  margin-left: auto;
  max-width: 90%;
}
section .cards .card .card-content-wrapper .card-content {
  display: block;
  background: white;
  transition: 0.5s ease;
  padding: 4.5rem 2rem 0 3.2rem;
}
section .cards .card .card-content-wrapper .card-content > div {
  text-align: right;
}
section .cards .card .card-content-wrapper .card-content h3,
section .cards .card .card-content-wrapper .card-content p {
  text-transform: capitalize;
}
section .cards .card .card-content-wrapper .card-content h3 {
  font-size: clamp(1.15rem, 1.1071rem + 0.1714vw, 1.3rem);
  font-weight: 800;
  color: #111;
}
section .cards .card .card-content-wrapper .card-content p {
  color: #454545;
  font-size: 0.9rem;
}
section .cards .card .card-content-wrapper .card-content svg {
  display: inline-block;
  text-align: right;
  width: 60px;
  color: #003ef5;
}
section .cards .card .card-img-wrapper:hover ~ .card-content-wrapper .card-content {
  background: #003ef5;
}
section .cards .card .card-img-wrapper:hover ~ .card-content-wrapper .card-content h3,
section .cards .card .card-img-wrapper:hover ~ .card-content-wrapper .card-content p,
section .cards .card .card-img-wrapper:hover ~ .card-content-wrapper .card-content svg {
  color: #fff;
}
section .cards .card .card-content-wrapper:hover .card-content {
  background: #003ef5;
}
section .cards .card .card-content-wrapper:hover .card-content h3,
section .cards .card .card-content-wrapper:hover .card-content p,
section .cards .card .card-content-wrapper:hover .card-content svg {
  color: #fff;
}



.team-title-background {
  background-color: #ec1c24; /* This is the color equivalent of bg-red-400 from Tailwind */
  color: white; /* Ensure text is white for readability */
  padding: 2px;
  border-radius: 0.5rem; /* Adjust as needed */
  font-weight: bold;
  font-size: 1.875rem; /* Adjust as needed */
  margin: 2%;
  text-align: center; /* Adjust as needed */
}





