.sec-2{
    display: flex;
    width: 100%;
}
.sec-2-1{
    width: 140%;
    position: relative;
}
.sec-2-1-img1{
    position: absolute;
    left: 10%;
    top: 20%;
}
.sec-2-1-img2{
    position: absolute;
    top: 49%;
    left: 70%;
}
.sec-2-1-text h3{
    position: absolute;
    top: 28%; 
    left: 55%; 
    transform: translate(-50%, -50%);
    width: 70%;
    color: #000;
    font-family: Poppins;
    font-size: 36px;
    font-weight: 400;
    background: transparent;
}
.sec-2-1-text h2{
    position: absolute;
    top: 47%; 
    left: 59%;
    background: transparent; 
    transform: translate(-50%, -50%);
    width: 80%;
    color: #364547;
    font-family: Poppins;
    font-size: 3rem;
    font-weight: 700;
    line-height: normal;
}
.sec-2-1-text p{
    position: absolute;
    top: 69%; 
    left: 49%;
    background: transparent; 
    transform: translate(-50%, -50%);
    width: 60%;
    color: #364547;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.42px;
}
.sec-2-1-text button{
    display:inline-flex;
    top: 80%;
    left: 17%;
    width: 63%;
    align-items: center;
    height: 9.5%;
    flex-shrink: 0;
    border-radius: 50px;
    border: 1px solid #EDEDED;
    background: #FFF;
    position: absolute;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.p-1{
    display: inline-flex;
    margin-top: 0.4rem;
    width: 20%;
    margin-left: 1.2rem;
    background: transparent;
    color: #8F8F8F;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
}
.img-1-1{
    width: 3%;
    height: 50%;
}
.btn-2-1{
    width: 18%;
    height: 60%;
    border-radius: 1rem;
    border: 2px solid #000;
    background: #FFF;
    box-shadow: 0px 5px 2px 0px #EC1C24;
}
.btn-2-1 h6{
    width: 100%;
    background: transparent;
    margin-top: 0.3rem;
    margin-bottom: 12px;
    color: #000;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
}
.sec-2-2{
    margin-top: 0.7rem;
    width: 90%;
    position: relative;
    background: transparent;
}
/* 768px 1024px and 650px */

@media only screen and (min-width:1024px) and (max-width:1440px){
    .sec-2-2{
        width: 70%;
    }
    .sec-2-1-text button{
        width: 76.5%;
    }
    .sec-2-1-text h3{
        top: 30%; 
        left: 71.5%; 
        font-size: 28px;
        width: 100%;
    }
    .sec-2-1-text h2{
        top: 54%; 
        left: 61%;
        font-size: 2.4rem;
    }
    .sec-2-1-img2{
        top: 56%;
        left: 76%;
    }
    .sec-2-1-text p{
        top: 81%; 
        left: 51%;
    }
    .btn-2-1{
        width: 19%;
    }
    .sec-2-1-text button{
        top: 91%;
        left: 20%;
        height: 10.5%;   
    }
    .p-1{
        width: 19%;
        margin-left: 1.0rem;
    }
    .img-1-1{
        width: 1%;
    }
    .btn-2-1{
        margin-left: 1.5rem;
    }
    .btn-2-1 h6{
        margin-top: 0.2rem;
    }
}


@media only screen and (min-width:768px) and (max-width:1023px){
    .sec-2-2{
        width: 80%;
    }
    .sec-2-2 img{
        margin-left: 4rem;
        height: 111%;
    }
    .sec-2-1-text h3{
        top: 32.5%; 
        left: 77.5%; 
        font-size: 22px;
        width: 100%;
    }
    .sec-2-1-text h2{
        top: 60%; 
        left: 68%;
        line-height: 2.8rem;
        font-size: 1.65rem;
    }
    .sec-2-1-img2{
        top: 59%;
        left: 86%;
        width: 16%;
    }
    .sec-2-1-text p{
        top: 85%; 
        left: 68%;
        width: 80%;
    }
    .btn-2-1{
        width: 19%;
    }
    .sec-2-1-text button{
        top: 96%;
        left: 28%;
        width: 85.5%;
    }
    .p-1{
        font-size: 13px;
        width: 40%;
        margin-left: 0.1rem;
    }
    .img-1-1{
        width: 1.2%;
    }
    .btn-2-1{
        width: 40%;
        margin-right: 0.4rem;
    }
    .btn-2-1 h6{
        margin-top: 0.2rem;
        font-size: 13px;
    }
}

@media only screen and (min-width:650px) and (max-width:760px) {
    .sec-2-1{
        width: 100%;
    }
    .sec-2-1-img2{
        position: relative;
        top: 190%;
        left: 72%;
    }
    .sec-2-1-text h3{
        top: 78%; 
        left: 72%; 
        width: 100%;
        font-size: 22px;
    }
    .sec-2-1-text h2{
        top: 195%; 
        left: 59%;
        width: 75%;
        font-size: 2.1rem;
    }
    .sec-2-1-text p{
        top: 305%; 
        left: 49%;
        width: 55%;
    }
    .sec-2-1-text button{
        top: 359%;
        left: 21%;
        width: 70%;
        height: 38%;
    }
    .p-1{
        margin-top: 0.2rem;
        width: 30%;
        margin-left: 0.4rem;
    }
    .img-1-1{
        width: 1%;
        height: 50%;
    }
    .btn-2-1{
        width: 20%;
        height: 65%;
        margin-right: 0.8rem;
    }
    .btn-2-1 h6{
        margin-top: 0.1rem;
        font-size: 12px;
    }
    .sec-2-2{
        position: absolute;
    }
    .sec-2-2 img{
        margin-top: 21rem;
        height: 20rem;
        margin-left: 9rem;
    }
}
